import React, { useState } from "react"

import * as S from "./styles"

import whatsapp from "../../images/whatsapp.svg"
import { Locale } from "./whatsapp"

const WhatsappList = () => {
  const [state, setState] = useState("")
  const [city, setCity] = useState("")

  state &&
    city &&
    console.log(
      Locale.filter(local => local.value === state)[0].cities.filter(
        cy => cy.value === city
      )[0].stores
    )
  return (
    <S.Wrapper>
      {/* <img src={logo} /> */}
      <p>
        Escolha a cidade abaixo e fale no WhatsApp com a <span>revenda</span>{" "}
        Vert Green mais próxima de você.
      </p>
      <select
        onChange={e => {
          console.log(e.target.value)
          console.log(
            Locale.filter(local => local.value === e.target.value)[0].cities[0]
              .value
          )
          setState(e.target.value)
          setCity("select")
        }}
      >
        <option value="" selected disabled>
          Selecione um estado
        </option>
        {Locale.map((local, i) => (
          <option key={local.value} value={local.value}>
            {local.state}
          </option>
        ))}
      </select>
      {state && (
        <select onChange={e => setCity(e.target.value)}>
          {Locale.filter(local => local.value === state)[0].cities.map(
            (city, i) => (
              <option
                key={city.value}
                value={city.value}
                selected={city.value === city}
              >
                {city.name}
              </option>
            )
          )}
        </select>
      )}
      <hr />
      {state &&
        city &&
        Locale.filter(local => local.value === state)[0]
          .cities.filter(cy => cy.value === city)[0]
          .stores.map(store => (
            <div key={`loja-${store.name}`} style={{ marginTop: "36px" }}>
              <h3>{store.name}</h3>
              {store.email && (
                <h4>
                  Email: <a href={`mailto:${store.email}`}>{store.email}</a>
                </h4>
              )}
              {store.instagram && (
                <h4>
                  Instagram:{" "}
                  <a
                    href={`https://instagram.com/${store.instagram}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    @{store.instagram}
                  </a>
                </h4>
              )}
              <S.WhatsAppButton href={`https://wa.me/${store.whatsapp}`}>
                <img src={whatsapp} alt="whatsapp" />
                <span>Whatsapp</span>
              </S.WhatsAppButton>
            </div>
          ))}
    </S.Wrapper>
  )
}

export default WhatsappList
