import * as React from "react"

import Layout from "../components/Layout/"
import Seo from "../components/seo"
import WhatsappHero from "../components/WhatsappHero"
import WhatsappList from "../components/WhatsappList"

const IndexPage = () => (
  <Layout>
    <Seo />
    <WhatsappHero />
    <WhatsappList />
  </Layout>
)

export default IndexPage
