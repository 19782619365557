import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: 16px;

  p {
    color: #3e7243;
    max-width: 250px;
    font-size: 18px;

    & > span {
      font-family: serif;
      font-size: 400%;
      color: #83ac35;
    }
  }

  h3 {
    color: #7b7979;
    font-size: 16px;
    margin-bottom: 8px;
  }

  h4 {
    color: #3e7243;
    font-weight: normal;
    font-size: 13px;
    margin: 0;
    line-height: 1.5;

    a {
      color: #3e7243;
    }
  }

  select {
    margin: 8px 0;
  }

  hr {
    margin: 24px 0 0;
  }
`

export const WhatsAppButton = styled.a`
  background: #3e7243;
  font-size: 14px;
  color: #fff;
  text-decoration: navajowhite;
  display: inline-flex;
  padding: 8px 20px;
  margin-top: 16px;
  align-items: center;
  border-radius: 20px;

  img {
    margin-right: 8px;
  }
`
