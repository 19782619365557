export const Locale = [
  {
    value: "BA",
    state: "Bahia",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "salvador",
        name: "Salvador",
        stores: [
          {
            name: "Verdíssima",
            email: "contato@verdissima.com.br",
            instagram: "verdissima_oficial",
            whatsapp: "+5571991747687",
          },
        ],
      },
    ],
  },
  {
    value: "CE",
    state: "Ceará",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "fortaleza",
        name: "Fortaleza",
        stores: [
          {
            name: "Plante Jardins",
            email: "pedidos@plantejardins.com",
            instagram: "plantejardins",
            whatsapp: "+5585981626262",
          },
        ],
      },
    ],
  },
  {
    value: "DF",
    state: "Distrito Federal",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "brasilia",
        name: "Brasília",
        stores: [
          {
            name: "Encanto Verde Paisagismo",
            email: "contato@encantoverdepaisagismo.com.br",
            instagram: "paisagismoencantoverde",
            whatsapp: "+5561992013015",
          },
        ],
      },
    ],
  },
  {
    value: "ES",
    state: "Espírito Santo",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "vitoria",
        name: "Vitória",
        stores: [
          {
            name: "Vitória Green Paisagismo",
            email: "contato@vitoriagreen.com.br",
            instagram: "vitoriagreen_paisagismo",
            whatsapp: "+5527998135472",
          },
        ],
      },
    ],
  },
  {
    value: "GO",
    state: "Goiás",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "anapolis",
        name: "Anápolis",
        stores: [
          {
            name: "Ogata Paisagismo",
            email: "ogata.paisagismo@gmail.com",
            instagram: "ogatapaisagismo ",
            whatsapp: "+5562991373820",
          },
        ],
      },
      {
        value: "goiania",
        name: "Goiânia",
        stores: [
          {
            name: "Vertgreen",
            email: "contato@vertgreen.com.br",
            instagram: "vertgreenjardinsverticais ",
            whatsapp: "+5562981354424",
          },
        ],
      },
      {
        value: "rioVerde",
        name: "Rio Verde",
        stores: [
          {
            name: "Aquanature",
            email: "aquanaturefish@gmail.com",
            instagram: "aquanature_lagos_ornamentais",
            whatsapp: "+5564992874277",
          },
        ],
      },
    ],
  },
  {
    value: "PA",
    state: "Pará",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "belem",
        name: "Belém",
        stores: [
          {
            name: "Namata Paisagismo",
            email: "contato@namatabr.com",
            instagram: "namata.paisagismo",
            whatsapp: "+5591984823333",
          },
        ],
      },
      {
        value: "santarem",
        name: "Santarém",
        stores: [
          {
            name: "Porã Paisagismo",
            email: "porapaisagismo@gmail.com",
            instagram: "porapaisagismo",
            whatsapp: "+5593991634366",
          },
        ],
      },
    ],
  },
  {
    value: "PR",
    state: "Paraná",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "londrina",
        name: "Londrina",
        stores: [
          {
            name: "iO PAISAGISMO",
            email: "io.paisagismo@gmail.com",
            instagram: "io.paisagismo",
            whatsapp: "+5543988199759",
          },
        ],
      },
      {
        value: "patoBranco",
        name: "Pato Branco",
        stores: [
          {
            name: "Jardinaria Paisagismo e Design Biofílico ",
            email: "jardinariapaisagismo@outlook.com",
            instagram: "jardinariapaisagismoedesignbio",
            whatsapp: "+5546991355145",
          },
        ],
      },
    ],
  },
  {
    value: "RJ",
    state: "Rio de Janeiro",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "rioDeJaneiro",
        name: "Rio de Janeiro",
        stores: [
          {
            name: "Cultive Amor Paisagismo",
            email: "cultiveamor.contato@gmail.com",
            instagram: "cultiveamorpaisagismo",
            whatsapp: "+5522997116101",
          },
        ],
      },
    ],
  },
  {
    value: "RS",
    state: "Rio Grande do Sul",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "portoAlegre",
        name: "Porto Alegre",
        stores: [
          {
            name: "Vert Green Porto Alegre",
            email: "vertgreenpoa@gmail.com",
            instagram: "",
            whatsapp: "+5551999000414",
          },
        ],
      },
    ],
  },
  {
    value: "SC",
    state: "Santa Catarina",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "florianopolis",
        name: "Florianópolis",
        stores: [
          {
            name: "Vert Green Santa Catarina",
            email: "vertgreensc@gmail.com",
            instagram: "vertgreensantacatarina",
            whatsapp: "+5548991577700",
          },
        ],
      },
    ],
  },
  {
    value: "SP",
    state: "São Paulo",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "franca",
        name: "Franca",
        stores: [
          {
            name: "Sapucaia Paisagismo",
            email: "atendimento@sapucaia.com.br",
            instagram: "sapucaiapaisagismo",
            whatsapp: "+551637238880",
          },
        ],
      },
      {
        value: "holambra",
        name: "Holambra",
        stores: [
          {
            name: "Hydroplantas",
            email: "info@hydroplantas.com.br",
            instagram: "hydroplantas",
            whatsapp: "+5519996601122",
          },
        ],
      },
      {
        value: "jundiai",
        name: "Jundiaí",
        stores: [
          {
            name: "Tello Paisagismo",
            email: "tellopaisagismo@gmail.com ",
            instagram: "",
            whatsapp: "+5511976544884",
          },
        ],
      },
      {
        value: "ourinhos",
        name: "Ourinhos",
        stores: [
          {
            name: "Iavor Paisagismo",
            email: "contato@iavorpaisagismo.com.br",
            instagram: "iavorpaisagismo",
            whatsapp: "+5514998039563",
          },
        ],
      },
      {
        value: "saoPaulo",
        name: "São Paulo",
        stores: [
          {
            name: "Ecobrasil Lagos e Jardins",
            email: "ecopaisagismobrasil@gmail.com",
            instagram: "ecobrasiljardinselagos",
            whatsapp: "+5511999144424",
          },
          {
            name: "Marcilene Monzani Paisagismo",
            email: "mmonzanipaisagismo@gmail.com",
            instagram: "mmonzanipaisagismo",
            whatsapp: "+5511970227368",
          },
        ],
      },
    ],
  },
  {
    value: "TO",
    state: "Tocantins",
    cities: [
      {
        value: "select",
        name: "Selecione uma cidade",
        stores: [],
      },
      {
        value: "palmas",
        name: "Palmas",
        stores: [
          {
            name: "Miriam Coelho Paisagismo",
            email: "vertgreenpalmas@gmail.com",
            instagram: "miriam84coelho",
            whatsapp: "+5563984211308",
          },
        ],
      },
    ],
  },
]
