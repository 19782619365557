import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const WhatsappHero = () => (
  <StaticImage
    src="../../images/vertgreen-jardim-fechado.png"
    width={600}
    quality={100}
    alt="Vert Green - Jardins Verticais"
    style={{ marginBottom: `1.45rem` }}
  />
)

export default WhatsappHero
